import ClientInfo from "shared-components/src/models/ClientInfo";
import { Activity } from "shared-components/src/models/Activity";
import { state } from "./state";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";
import SnakbarModel from "shared-components/src/models/SnakbarModel";
import SearchEmployeeRequest from "shared-components/src/models/requests/SearchEmployeeRequest";

export interface AppState {
  quickLoginDialog: boolean;
  isProfileDropdownOpen: boolean;
  isNotificationOpen: boolean;
  clientInfo: ClientInfo;
  activities: Activity[];
  drawerIsOpen: boolean;
  showLoading: boolean;
  idToken: string;
  snakbars: SnakbarModel[];
  searchEmployeeCriteria: SearchEmployeeRequest;
  newProjectPopup: boolean;
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [createPersistedState()],
});
