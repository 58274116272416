import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "login-form" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_flex = _resolveComponent("v-flex")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.emailStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_flex, {
            xs12: "",
            class: "text-left"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                ref: "frmMain",
                modelValue: _ctx.valid,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
                "lazy-validation": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TextField, {
                    rules: [_ctx.rules.required, _ctx.rules.EmailCheck],
                    "prepend-icon": "mdi-account",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                    label: "Email"
                  }, null, 8, ["rules", "modelValue"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_btn, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendLink())),
              loading: _ctx.isLoading,
              color: "accent"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Send me a magic link")
              ])),
              _: 1
            }, 8, ["loading"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, "We sent an email to " + _toDisplayString(_ctx.email), 1)
        ]))
  ]))
}