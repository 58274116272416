import { AppState } from ".";
import ClientInfo from "shared-components/src/models/ClientInfo";
import SnakbarModel from "shared-components/src/models/SnakbarModel";

// import noProfileImage from "@/assets/images/no-profile.png";
// import noProfileImage from "../assets/images/no-profile.png"
// export const snakbarState: SnakbarModel = {
//   id: "",
//   body: "",
//   timeout: 5000,
//   status: "",
//   button: "",
//   show: false,
//   handler: () => null
// };
export const snakbarState: SnakbarModel = {
  id: "",
  body: "",
  timeout: 5000,
  status: "",
  button: "",
  show: false,
  handler: () => null,
};

export const defaultUser: ClientInfo = {
  email: "",
  fullName: "guest user",
  firstName: "guest",
  lastName: "user",
  userId: "",
  roles: [] as string[],
  IsActive: false,
  id: undefined,
};

export const state: AppState = {
  quickLoginDialog: false,
  isProfileDropdownOpen: false,
  isNotificationOpen: false,
  drawerIsOpen: false,
  clientInfo: { ...defaultUser },
  activities: [],
  showLoading: false,
  idToken: "",
  snakbars: [],
  searchEmployeeCriteria: {},
  newProjectPopup: false,
};
