<template>
  <v-dialog v-model="showCommitmentMenuDialog" persistent max-width="1000px">
    <v-card class="pa-3" :loading="dialogLoading" :disabled="dialogLoading">
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            class="text-center"
            v-if="selectedCommitment?.TeammemberId"
          >
            <Avatar
              :Name="selectedCommitment?.TeammemberName"
              :Photo="selectedCommitment?.PhotoUrl"
              class="tm-avatar"
            />
            <span class="tm-name">
              {{ selectedCommitment?.TeammemberName }}
            </span>
          </v-col>
          <v-icon class="close-btn" @click="closeDialog">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 info-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      {{ selectedCommitment?.Name }}
                    </v-col>
                    <!-- <v-row>
                    <v-col cols="12"> Epics: {{ getEpics }} </v-col>
                  </v-row> -->
                    <v-col cols="12">
                      Project Name:
                      {{ truncate(selectedCommitment?.ProjectName) }}
                    </v-col>
                    <v-col cols="6">
                      Start Date:
                      {{ formatDate(selectedCommitment?.StartDate) }}
                    </v-col>
                    <v-col cols="6">
                      End Date: {{ formatDate(selectedCommitment?.EndDate) }}
                    </v-col>
                    <v-col cols="12">
                      Project Lead: {{ selectedCommitment?.ProjectLeadName }}
                    </v-col>
                    <v-col cols="12">
                      Portfolio Manager:
                      {{ selectedCommitment?.PortfolioManagerName }}
                    </v-col>
                    <v-col cols="12">
                      Holiday Zone:
                      {{ selectedCommitment?.PublicHolidayZoneName }}
                    </v-col>
                    <v-col cols="6">
                      Location Type:
                      {{
                        selectedCommitment
                          ? selectedCommitment?.LocationType
                          : ""
                      }}
                    </v-col>
                    <v-col cols="6">{{ workingDays }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 info-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">Addons Info</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      Timesheet Processing:
                      <v-icon v-if="selectedCommitment?.TimesheetProcessing">
                        mdi-check
                      </v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      OfficeSpace:
                      <v-icon v-if="selectedCommitment?.OfficeSpaceId">
                        mdi-check
                      </v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">Training Roadmaps: -</v-col>
                    <v-col cols="6">
                      Contract:
                      <v-icon
                        v-if="
                          selectedCommitment?.ContactVerification ||
                          selectedCommitment?.ContractManagement ||
                          selectedCommitment?.IDVerification
                        "
                      >
                        mdi-check
                      </v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      Virtual Desktop:
                      <v-icon v-if="selectedCommitment?.VirtualDesktopSpec">
                        mdi-check
                      </v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      Payroll:
                      <v-icon v-if="selectedCommitment?.SalaryCurrency">
                        mdi-check
                      </v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">
                      Line Manager:
                      <v-icon v-if="selectedCommitment?.LineManagerId">
                        mdi-check
                      </v-icon>
                      <span v-else>-</span>
                    </v-col>
                    <v-col cols="6">TDM: -</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 action-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">Actions</v-col>
                  </v-row>
                  <div
                    class="menu-action-btns"
                    v-if="
                      selectedCommitment &&
                      selectedCommitment.AllowedActions &&
                      selectedCommitment.AllowedActions?.length > 0
                    "
                  >
                    <!-- <v-btn class="menu-btn" prepend-icon=""
                      >Extend Contract</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon=""
                      >Terminate Contract</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon=""
                      >Terminate Position</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon=""
                      >Plan Replacement</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon="">Rate Change</v-btn>
                    <v-btn class="menu-btn" prepend-icon=""
                      >Access Update</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon=""
                      >Request 1-On-1</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon="">Bonus</v-btn>
                    <v-btn class="menu-btn" prepend-icon=""
                      >Feedback/Warning</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon=""
                      >Change Manager</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon=""
                      >Change Work Hours</v-btn
                    >
                    <v-btn class="menu-btn" prepend-icon="">Office</v-btn> -->
                    <v-btn
                      v-if="
                        checkActionPermission(
                          actionLists.CustomerAddVirtualDesktop
                        )
                      "
                      class="menu-btn"
                      prepend-icon=""
                      @click="showAddVirtualDeskopAction()"
                    >
                      Add Virtual Desktop
                    </v-btn>
                    <v-btn
                      v-if="
                        checkActionPermission(actionLists.CustomerVdSupport)
                      "
                      class="menu-btn"
                      prepend-icon=""
                      @click="openVdSupport()"
                    >
                      VD Support
                    </v-btn>
                    <v-btn
                      v-if="
                        checkActionPermission(
                          actionLists.CustomerModifyVirtualDesktop
                        )
                      "
                      class="menu-btn"
                    >
                      Modify Virtual Desktop
                    </v-btn>
                    <v-btn
                      v-if="
                        checkActionPermission(
                          actionLists.CustomerTerminateVirtualDesktop
                        )
                      "
                      class="menu-btn"
                    >
                      Terminate Virtual Desktop
                    </v-btn>
                  </div>
                  <div v-else class="no-action-container">
                    <span class="note">
                      You have already submitted a request to make the following
                      changes to this Commitment for
                      {{
                        formatDate(
                          selectedCommitment?.CommitmentActions?.EffectiveDate
                        )
                      }}, if you wish to Cancel/Update them, please contact an
                      Administrator.
                    </span>
                    <div
                      v-for="(action, index) in selectedCommitment
                        ?.CommitmentActions?.ActionItems"
                      :key="index"
                    >
                      {{ getActionName(action) }}
                      <v-icon
                        size="small"
                        icon="mdi-information"
                        v-tooltip:top="getActionDetail(action)"
                      ></v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card variant="tonal" class="b-r-5 action-boxs">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">Insights</v-col>
                  </v-row>
                  <div class="menu-action-btns">
                    <v-btn class="menu-btn">Current Status</v-btn>
                    <v-btn class="menu-btn">Timesheet Flag Yesterday</v-btn>
                    <v-btn class="menu-btn">Timesheet Flag Last Week</v-btn>
                    <v-btn class="menu-btn">Contract Ending Soon</v-btn>
                    <v-btn class="menu-btn">Add-On Upsell</v-btn>
                    <v-btn class="menu-btn">Notification</v-btn>
                    <v-btn class="menu-btn">Attention</v-btn>
                    <v-btn class="menu-btn">Issues</v-btn>
                    <v-btn
                      class="menu-btn"
                      v-if="selectedCommitment?.VmId"
                      @click="openActivityReport()"
                    >
                      Activity Report
                    </v-btn>
                    <v-btn
                      class="menu-btn"
                      v-if="selectedCommitment?.VmId"
                      @click="openSecurityReport()"
                    >
                      Security Report
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog
          v-model="displayActivityReport"
          v-if="displayActivityReport && selectedCommitment?.VmId"
          max-width="90%"
          @keydown.esc="closeActivityWatchReport"
        >
          <v-card>
            <v-card-text>
              <ActivityWatchReport
                :vmId="selectedCommitment?.VmId"
                @Close="closeActivityWatchReport"
                :isClient="true"
              ></ActivityWatchReport>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="displaySecurityReport"
          v-if="displaySecurityReport && selectedCommitment?.VmId"
          max-width="900"
          persistent
          @keydown.esc="closeSecurityReport"
        >
          <v-card>
            <v-card-text>
              <SecurityReport
                :vmId="selectedCommitment?.VmId"
                @close="closeSecurityReport"
                :isClient="true"
              ></SecurityReport>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="displayVdSupport"
          max-width="900"
          persistent
          @keydown.esc="closeVdSupportPopup"
        >
          <VdSupport
            :isClient="true"
            @cancel="closeVdSupportPopup"
            @sendRequest="createVdSupport"
          ></VdSupport>
        </v-dialog>

        <CommitmentActionModal
          v-if="showActionModal"
          :commitmentAction="selectedCommitmentAction"
          :commitmentId="getCommitmentId()"
          @OnClose="closeAddVirtualDeskopAction"
          @OnSuccess="closeAddVirtualDeskopAction"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import {
    ClientCommitmentApi,
    ClientGetCommitmentByIdResponseModel,
    CommitmentActionItem,
    CommitmentActionItemTypeEnum,
    CommitmentActionWithAltItem,
    CommitmentActionWithAltItemTypeEnum,
  } from "shared-components/src/services/openApi/api"
  import ProjectSearchWizard from "../AddTeammember/ProjectSearchWizard.vue"
  import AppHelper from "shared-components/src/utils/AppHelper"
  import Utils from "shared-components/src/utils/Utils"
  import ActivityWatchReport from "shared-components/src/components/Reports/ActivityWatch/ActivityWatchReport.vue"
  import SecurityReport from "shared-components/src/components/Reports/Wazuh/Dashboard.vue"
  import VdSupport from "shared-components/src/components/Vm/VdSupport.vue"
  import CommitmentActionModal from "./CommitmentActionModal.vue"
  import {
    ClientVMApi,
    ClientVmSupportRequest,
  } from "shared-components/src/services/openApi/api"
  import store from "@/store"
  import Avatar from "@/components/Avatar.vue"

  export default defineComponent({
    components: {
      ProjectSearchWizard,
      ActivityWatchReport,
      VdSupport,
      SecurityReport,
      Avatar,
      CommitmentActionModal,
    },
    props: [
      "details",
      "project",
      "projectId",
      "teamMember",
      "commitmentActions",
    ],
    data() {
      return {
        showActionModal: false,
        showVdSupportModal: false,
        displayVdSupport: false,
        displaySecurityReport: false,
        displayActivityReport: false,
        dialogLoading: false,
        showSearchTeammember: false,
        addLoading: false,
        showUpdatePosition: false,
        showCommitmentMenuDialog: true,
        selectedCommitment: null as ClientGetCommitmentByIdResponseModel | null,
        selectedCommitmentAction: null as null | CommitmentActionWithAltItem,
        actionLists: CommitmentActionItemTypeEnum,
      }
    },
    async created() {
      const commitmentId = this.getCommitmentId()
      if (commitmentId) {
        try {
          this.dialogLoading = true
          this.selectedCommitment = (
            await new ClientCommitmentApi().clientGetCommitment(commitmentId)
          ).data
          this.dialogLoading = false
        } catch {
          this.closeDialog()
        }
      }
    },
    mounted() {},
    methods: {
      getActionName(action: CommitmentActionItem) {
        switch (action.Type) {
          case CommitmentActionItemTypeEnum.CustomerAddVirtualDesktop:
            return "Add Virtual Desktop"
        }
      },
      getActionDetail(action: CommitmentActionItem) {
        switch (action.Type) {
          case CommitmentActionItemTypeEnum.CustomerAddVirtualDesktop:
            return `Cpu: ${action.Details?.VirtualDesktop?.Cpu}, Memory: ${action.Details?.VirtualDesktop?.Ram}, Disk: ${action.Details?.VirtualDesktop?.Disk}`
        }
      },
      checkActionPermission(action: CommitmentActionItemTypeEnum) {
        return this.selectedCommitment?.AllowedActions?.includes(action)
      },
      truncate(item: any) {
        if (item && item.length > 30) {
          return item.substring(0, 30) + "..."
        } else {
          return item
        }
      },
      formatDate(date: any) {
        date = new Date(date)
        if (date) {
          return Utils.toVsDateFormat(date)
        }
      },
      getCommitmentId() {
        return this.teamMember?.CommitmentId ?? this.$route.query.commitmentId
      },
      showAddVirtualDeskopAction() {
        this.selectedCommitmentAction = this.commitmentActions.find(
          (item: CommitmentActionWithAltItem) =>
            item.Type ==
            CommitmentActionWithAltItemTypeEnum.CustomerAddVirtualDesktop
        )
        this.showActionModal = true
      },
      closeAddVirtualDeskopAction() {
        this.selectedCommitmentAction = null
        this.showActionModal = false
      },
      closeVdSupportPopup() {
        this.displayVdSupport = false
      },
      closeSecurityReport() {
        this.displaySecurityReport = false
      },
      closeActivityWatchReport() {
        this.displayActivityReport = false
      },
      openVdSupport() {
        if (!this.selectedCommitment?.VmId) {
          return
        } else {
          this.displayVdSupport = true
        }
      },
      openSecurityReport() {
        if (this.selectedCommitment?.VmId) {
          this.displaySecurityReport = true
        }
      },
      openActivityReport() {
        if (this.selectedCommitment?.VmId) {
          this.displayActivityReport = true
        }
      },
      closeDialog() {
        this.$emit("OnClose")
      },
      GetColour(name: any) {
        return AppHelper.GetColour(name)
      },
      GetInitials(name: any) {
        return AppHelper.GetInitials(name)
      },
      async createVdSupport(event: any) {
        if (this.selectedCommitment?.VmId) {
          try {
            const result = (
              await new ClientVMApi().clientVmSupport(
                this.selectedCommitment?.VmId,
                {
                  Message: event.Message,
                  Title: event.Title,
                } as ClientVmSupportRequest
              )
            ).data
            this.displayVdSupport = false
            store.dispatch(
              "showSuccessMessage",
              "Vd support request submitted successfully"
            )
          } catch {
            store.dispatch(
              "showErrorMessage",
              "An error occurred while submitting the Vd support request"
            )
          } finally {
          }
        }
      },
    },
    computed: {
      avatarColor() {
        const color = this.selectedCommitment?.PhotoUrl
          ? null
          : this.GetColour(this.selectedCommitment?.Name)
        return typeof color === "string" ? color : undefined
      },
      getEpics() {
        return this.project?.Epics ? this.project.Epics.join(", ") : "No Epics"
      },
      workingDays(): string {
        return this.selectedCommitment?.WorkingDaysJson
          ? JSON.parse(this.selectedCommitment?.WorkingDaysJson).join(", ")
          : ""
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/color.scss";
  @import "node_modules/shared-components/assets/style/font.scss";
  .no-action-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px;
    align-items: center;
    .note {
      color: $c_orange;
      @extend .f-body-2;
      text-align: center;
    }
  }
  .info-boxs {
    height: 100%;
    min-height: 250px;
    padding: 5px;
  }
  .action-boxs {
    min-height: 380px;
  }
  .menu-action-btns {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .menu-btn {
      font-size: 12px;
      flex: 45%;
    }
  }
  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    color: white;
  }
  .tm-name {
    padding-left: 5px;
  }
</style>
